import CryptoJS from 'crypto-js'
import { config } from '../config/config'

const passPhrase = config.passPhrase

export const encryptFunc = (plainText) => {
  return CryptoJS.AES.encrypt(plainText, passPhrase).toString()
}

export const decryptFunc = (cryptText) => {
  return CryptoJS.AES.decrypt(cryptText, passPhrase).toString(CryptoJS.enc.Utf8)
}

export const hashFunc = (data) => {
  return data ? CryptoJS.SHA256(data).toString() : null
}
