import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { DataStore, SortDirection } from 'aws-amplify'
import moment from 'moment'
import { Talk, Read } from '../../models'
import { formatContents } from '../../datastores/talk'
import { createToken, getItem } from '../../datastores/fcmToken'
import { getLastRead, updateLastRead } from '../../datastores/read'
import { getNotificationToken, onReceiveMessage } from '../../firebase'
import { updateTalkRoomList } from '../../modules/indexedDB'
import Unread from '../atoms/Unread'
import TxtDate from '../atoms/TxtDate'
import Loader from '../atoms/Loader'
import SelfTalk from '../molecules/SelfTalk'
import OtherTalk from '../molecules/OtherTalk'
import './talkRoom.scss'

export default function TalkRoom(props) {
  const { me, roomUsers, roomId } = props
  const [talks, setTalks] = useState([])
  const [othersTalkIds, setOthersTalkIds] = useState([])
  const [latestReadTalk, setLatestReadTalk] = useState(undefined)
  const [latestUnreadTalk, setLatestUnreadTalk] = useState(undefined)
  const [clearUnread, setClearUnread] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingScroll, setIsLoadingScroll] = useState(true)
  const [isFirstAccess, setIsFirstAccess] = useState(true);
  
  useEffect(() => {
    if (!me) return

    getNotificationToken().then(currentToken => {
      if (currentToken) {
        getItem(me.id, me.roomID, currentToken).then(totalItems => {
          if (totalItems.length === 0) {
            createToken(me.id, me.roomID, currentToken)
          }
        })
      }
    }).catch((e) => {
      console.log("Something wrong happened", e)
    })
    onReceiveMessage(me)

    updateTalkRoomList(me.id, roomId)
  }, [me])

  const roomAreaRef = useRef(null);

  useEffect(() => {
      const observer = new MutationObserver(() => {
          setIsLoadingScroll(true);
          setTimeout(() => {
              const { scrollHeight } = roomAreaRef.current;
              roomAreaRef.current.scrollTop = scrollHeight;
              setIsLoadingScroll(false);
              setIsFirstAccess(false);
          }, 1000);
      });

      if (roomAreaRef.current) {
          observer.observe(roomAreaRef.current, { attributes: true, childList: true, subtree: true });
      }

      setIsLoadingScroll(false);

      return () => {
          observer.disconnect();
      }
  }, [roomAreaRef.current]);

  useEffect(() => {
    if (othersTalkIds.length > 0) {
      if (latestReadTalk === undefined) {
        updateLastRead(me.id, roomId, othersTalkIds[othersTalkIds.length - 1])
      } else {
        const index = othersTalkIds.indexOf(latestReadTalk)
        updateLastRead(me.id, roomId, othersTalkIds[othersTalkIds.length - 1], index)
      }
    }
  }, [othersTalkIds])

  useEffect(() => {
    let isCancelled = false;

    const subscription = DataStore.observeQuery(
      Talk,
      t => t.roomID('eq', me.roomID)
        .sendAt('gt', moment().add(-2, 'd').format()),
      {sort: s => s.sendAt(SortDirection.ASCENDING)}
    ).subscribe(async res => {
      if (isCancelled) return;
      const { items, isSynced } = res
      if (!isSynced) return
      if (!items || items.length === 0) {
        setIsLoading(false)
        return
      }

      let tempOthersTalkIds = []
      items.forEach(i => { if (i.userID !== me.id) tempOthersTalkIds.push(i.id) })
      setOthersTalkIds(tempOthersTalkIds)

      const tempLatestReadTalk = await getLastRead(me.id, roomId)
      setLatestReadTalk(tempLatestReadTalk ? tempLatestReadTalk.talkID : null)

      const tempFormatContents = await formatContents(items, me.id, tempLatestReadTalk ? tempLatestReadTalk.talkID : null)
      const contents = tempFormatContents.result
      setTalks(contents)
      setLatestUnreadTalk(tempFormatContents.latestUnRead)

      if (contents.length === 0) return
      setIsLoading(false)
    });

    return () => {
      isCancelled = true;
      subscription.unsubscribe();
    };
  }, [])

  return (
    <>
      {isLoadingScroll && isFirstAccess && <Loader />}
      <div className="roomArea" ref={roomAreaRef}>
        {talks.map((t, i) => {
          if (t.type === 'date') return <TxtDate key={i} date={t.date} />
          if (t.type === 'self')
            return <SelfTalk key={i} talkId={t.id} talk={t.contents} file={t.path} fileType={t.fileType} presignedUrl={t.preSignedUrl} sendAt={t.showSendAt} />
          if (t.type === 'other')
            /* FIXME: 新着トーク表示 -> 既読の間に「ここから未読」の表示が一瞬チラつくのでひとまずコメントアウトで機能を殺す
            if (latestUnreadTalk !== undefined && latestUnreadTalk === t.id && !clearUnread ) {
              return(
                <>
                  <Unread/>
                  <OtherTalk key={i} talkId={t.id} nickname={roomUsers[t.userID]} talk={t.contents} file={t.path} fileType={t.fileType} presignedUrl={t.preSignedUrl} sendAt={t.showSendAt} />
                </>
              )
            }
             */
            return <OtherTalk key={i} talkId={t.id} nickname={roomUsers[t.userID]} talk={t.contents} file={t.path} fileType={t.fileType} presignedUrl={t.preSignedUrl} sendAt={t.showSendAt} />
        })}
      </div>
    </>
  )
}
