import React from 'react'
import { Image } from '@aws-amplify/ui-react'

export default function ButtonClose(props) {
  const { clickAction } = props

  return (
    <button onClick={clickAction}>
      <Image src='/images/icon_close.svg' />
    </button>
  )
}
