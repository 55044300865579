import { Image } from '@aws-amplify/ui-react'

export const reformatContents = (s) => {
  if (s.match(/https?:\/\/.+/g))
    return <a href={s} rel='noopener noreferrer' target='_blank'>{s}</a>

  return <p>{s}</p>
}

export const reformatFile = (file, fileType, presignedUrl, nickname = null) => {
  if (presignedUrl === false || fileType.indexOf('image') !== -1)
    // TODO presignedUrl === false用の画像のURLをセットする
    return (
      <div className="chatItem">
        {nickname &&
          <p className="chatName">{nickname}</p>
        }
        <Image className="image" src={presignedUrl} onError={e => imgError(e)} />
      </div>
    )

  const fileSplit = file.split('/')
  return (
    <div className="chatItem">
      {nickname &&
        <p className="chatName">{nickname}</p>
      }
      <a className="fileBox" href={presignedUrl}>
        <Image src="/images/icon_file.png" />
        <div className="fileText">
          <p className="fileName">{fileSplit[fileSplit.length - 1]}</p>
          <p className="fileType">{fileType}</p>
        </div>
      </a>
    </div>
  )
}

const imgError = (e) => {
  // MEMO: ファイルのアップロードは非同期で動いており、表示時にリンク切れになることがあるため、明示的に再度srcをセット
  e.target.src = e.target.src
}
