import React from 'react'
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'
import FooterMenu from '../organisms/FooterMenu'
import './rule.scss'

export default function Rule(props) {
  return (
    <>
      <Header />
      <div className="fixedPageArea">
        <h1>利用規約</h1>
        <h2>第 1 条（目的）</h2>
        <p>この利用規約（以下「本規約」といいます）は、お客様が、Txtbase運営事務局（以下「当事務局」といいます）が運営し提供するWEBサイト「Txtbase（てきすとべーす）」（関連するサービスを含み、以下「本サービス」といいます）をご利用頂くにあたっては、本規約の全文をお読み頂いたうえで、本規約の全ての条項について承諾頂く必要があります。<br />
        本サービスをご利用頂いた場合、利用者の方が本規約の内容を理解しており、かつ、本規約の全ての条項について承諾したものとみなします。</p>
        <h2>第 2 条（定義）</h2>
        <p>本規約上で使用する用語の定義は、次に掲げる通りとします。</p>
        <p>（1） 本サービス: 利用者同士がWEBサイトにアクセスすることで、コンテンツを介したコミュニケーションが可能となる「Txtbase（てきすとべーす）」を指します。<br />
        （2） 本契約：本規約を契約条件として当事務局及びお客様との間で締結される、本サービスの利用契約を指します。<br />
        （3） コンテンツ：文字、音声、画像、動画、ソフトウェア、プログラム、その他の情報を指します。<br />
        （4） 投稿コンテンツ：本サービスに投稿、送信、アップロードしたコンテンツを指します。<br />
        （5） 個人情報：生存する個人に関する住所、氏名、メールアドレス、電話番号等特定の個人を識別することのできる情報（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます）を指します。<br />
        （6） 知的財産：発明、考案、意匠、著作物その他の人間の創造的活動により生み出されるもの、商標、商号その他事業活動に用いられる商品又は役務を表示するもの及び営業秘密その他の事業活動に有用な技術上又は営業上の情報を指します。<br />
        （7） 知的財産権：特許権、実用新案権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利又は法律上保護される利益に係る権利を指します。</p>
        <h2>第 3 条（規約への同意）</h2>
        <p>1. 本サービスの利用は、本規約、当事務局プライバシーポリシー（以下「プライバシーポリシー」といいます）その他当事務局の定めるルールに従うものとします。本規約、プライバシーポリシー間で定める内容に不一致がある場合は、当事務局の裁量で適用すべき事項を決定するものとします。</p>
        <p>2. お客様は本規約に同意することにより、当事務局との間に本契約（第2条で定義します）が成立します。</p>
        <p>3. お客様が未成年者である場合には、親権者その他の法定代理人の同意を得たうえで、本サービスをご利用いただくものとします。<br />
        4. 本規約の同意時に未成年であったお客様が成年に達した後に本サービスを利用した場合、お客様は本サービスに関する一切の法律行為を追認したものとみなします。</p>
        <h2>第 4 条（規約の変更）</h2>
        <p>1. 当事務局は、以下の各号のいずれかに該当する場合は、民法第548条の4の規定に基づき本規約を随時変更できます。本規約が変更された後の本契約は、変更後の本規約が適用されます。</p>
        <ul>
          <li>本規約の変更が、お客様の一般の利益に適合するとき</li>
          <li>本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性及びその内容その他の変更に係る事情に照らして合理的なものであるとき</li>
        </ul>
        <p>2. 当事務局は、本規約の変更を行う場合は、変更後の本規約の効力発生時期を定め、効力発生時期の１週間前までに、変更後の本規約の内容及び効力発生時期を本サービス上への表示その他当事務局所定の方法によりお客様に周知します。</p>
        <p>3. 前二項の規定にかかわらず、前項の本規約の変更の周知後にお客様が本サービスを利用した場合又は当事務局所定の期間内にお客様が解約の手続をとらなかった場合、当該お客様は本規約の変更に同意したものとします。</p>
        <h2>第 5 条（登録情報の管理）</h2>
        <p>1. お客様は、本サービスの利用を開始するにあたり、当事務局所定の情報の登録（以下「登録情報」といいます）を行う必要があります。お客様ご自身で登録した内容の不備や間違いによって発生したトラブルに関して、当事務局は一切の責任を負いません。</p>
        <p>2. 当事務局は、登録情報を申請したお客様が以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。</p>
        <ul>
          <li>本規約に違反するおそれがあると当事務局が判断した場合</li>
          <li>登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合</li>
          <li>過去に登録情報の削除を受けた者である場合</li>
          <li>反社会的勢力等（第10条で定義する） に該当すると当事務局が判断した場合</li>
          <li>その他、当事務局が登録を適当でないと判断した場合</li>
        </ul>
        <p>３. 本サービスの登録情報は、第三者に譲渡、貸与又は相続させることはできません。 お客様は、登録情報を利用する際に登録した内容が、第三者により不正に利用されないよう、お客様ご自身の責任でこれらを管理しなければなりません。</p>
        <p>4. 登録情報が盗用され又は第三者に利用されていることが判明した場合、お客様は直ちにその旨を当事務局に通知するとともに、当事務局からの指示に従うものとします。</p>
        <h2>第 6 条（広告表示）</h2>
        <p>当事務局は、本サービスに当事務局または第三者の広告を掲載することができます。</p>
        <h2>第 7 条（サービスの内容変更と中断等の措置）</h2>
        <p>1. 当事務局は、本サービス及び本サービスを利用して提供される一切のサービスについて、本サービスを適正かつ円滑に運営するためお客様に通知することなく、いつでも任意に内容を変更できるものとします。</p>
        <p>2. 当事務局は、以下の各号のいずれかに該当する場合には、お客様に通知することなく、本サービスの全部又は一部の中断等の措置を講じることができるものとします。</p>
        <ul>
          <li>本サービスに係るコンピュータシステムの点検又は保守作業を定期的又は緊急に行う場合</li>
          <li>コンピュータ、通信回線、その他の設備等が事故等により停止した場合</li>
          <li>火災、停電、天災地変などの不可抗力により本サービスの運営に支障が生じた場合</li>
          <li>ハッキングその他の方法により当事務局が管理するシステムに異常が生じた場合</li>
          <li>その他、当事務局が利用停止又は中断が必要と判断した場合</li>
        </ul>
        <h2>第 8 条（サービスの終了）</h2>
        <p>1. 当事務局は、当事務局の都合により、本サービスの提供を終了（サービスの同一性を維持しないかたちで、本サービスの内容や仕様の全部又は一部について、異なる内容や仕様に変更することを含みます）することができます。この場合、当事務局はお客様に事前に通知するよう努めるものとします。</p>
        <p>2. 本サービスを終了する場合、本サービスにおいてお客様に関連する一切のデータは削除されるものとします。</p>
        <h2>第 9条（禁止行為）</h2>
        <p>本サービスの利用に際し、当事務局は、お客様に対し、以下の各号に掲げる行為、これらを助長する行為又はこれらに該当するおそれのある行為を禁止します。</p>
        <ul>
          <li>本規約、法令、又は公序良俗に違反する行為</li>
          <li>当事務局又は第三者の知的財産権を侵害する行為</li>
          <li>当事務局又は第三者の名誉・信用を毀損又は不当に差別若しくは誹謗中傷する行為</li>
          <li>当事務局又は第三者の財産を侵害する行為、又は侵害する恐れのある行為</li>
          <li>当事務局又は第三者に経済的損害を与える行為</li>
          <li>当事務局又は第三者に対する脅迫的な行為</li>
          <li>コンピューターウィルス、有害なプログラムを送信する行為</li>
          <li>本サービス用インフラ設備に対して過度な負担をかける行為</li>
          <li>本サービスを構成するサーバーやシステム、セキュリティに攻撃又は改竄を行う行為</li>
          <li>当事務局提供のインターフェース以外の方法で本サービスにアクセスをする行為</li>
          <li>営業、宣伝、広告、勧誘、その他営利を目的とする行為（ただし、当事務局が同意したものを除く）</li>
          <li>犯罪収益に関する行為、テロ資金供与に関する行為、又はその疑いがある行為</li>
          <li>上記の他、当事務局が不適切と合理的に判断する行為</li>
        </ul>
        <h2>第 10 条（反社会的勢力の排除）</h2>
        <p>1. お客様は、現在かつ将来にわたり、反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロ、又は特殊知能暴力集団等、その他これらに準ずる者をいいます）に該当しないこと、また暴力的な要求行為、法的な責任を超えた不当な要求行為、取引に関して脅迫的な言動をし暴力を用いる行為、風説を流布し偽計を用い又は威力を用いて信用を毀損し又は業務を妨害する行為、その他これらに準ずる行為を行わないことを、現在及び将来にわたって表明するものとします。</p>
        <p>2. 当事務局は、お客様が前項のいずれかに該当すると判断した場合、何らの事前通知を要さず、当該お客様の登録情報の削除その他必要な一切の措置を講ずるものとします。当該お客様は、当事務局による当該措置に対して、損害賠償請求その他一切の権利を行使することはできないものとします。</p>
        <h2>第 11 条（お客様の責任）</h2>
        <p>1. お客様は、お客様ご自身の責任において、本サービスを利用するものといたします。お客様は、本サービスにおいて行った一切の行為およびその結果について一切の責任を負うものとします。</p>
        <p>2. お客様は、本サービスを利用したことに起因して（当事務局がかかる利用を原因とするクレームを第三者より受けた場合を含みます。）、当事務局が直接的または間接的に何らかの損害（弁護士費用の負担を含みます。）を被った場合、当事務局の請求にしたがって直ちにこれを賠償しなければなりません。お客様は、本サービスの退会を希望する場合、当事務局所定の手続きを行うことにより退会することができます。</p>
        <h2>第 12 条（利用制限）</h2>
        <p>1. 当事務局は、お客様が以下のいずれかに該当する場合には、お客様に事前に通知することなく、お客様に対して、本サービスの全部若しくは一部の利用の制限その他の当事務局が必要かつ適切と合理的に判断する措置を講じることができるものとします。</p>
        <ul>
          <li>本規約のいずれかの条項に違反した場合</li>
          <li>当事務局からの連絡に対し、一定期間返答がない場合</li>
          <li>本サービスについて、最終の利用から一定期間利用がない場合</li>
          <li>その他、当事務局が本サービスの利用を適当でないと合理的に判断した場合</li>
        </ul>
        <p>2. お客様は、本サービスを利用したことに起因して（当事務局がかかる利用を原因とするクレームを第三者より受けた場合を含みます。）、当事務局が直接的又は間接的に何らかの損害（逸失利益及び合理的な弁護士費用を含みます。）を被った場合、当事務局の請求にしたがってこれを賠償するものとします。</p>
        <h2>第 13 条（知的財産権等）</h2>
        <p>1. お客様が本サービス上において投稿等を行った場合、著作物性の有無を問わず、掲載内容の一部又は全部に関し、発生しうる全ての著作権（著作権法第27条及び第28条に定める権利を含みます。）について、本サービスの提供に必要な範囲で、無償かつ無制限に利用できる権利を当事務局に対して許諾することについて同意します。</p>
        <p>2. お客様は、方法又は形態の如何を問わず、本サービスにおいて提供される全ての情報及びコンテンツ並びに商品（以下総称して「当事務局コンテンツ」といいます。）はお客様に対して利用許諾されるものであり、著作権法に定める、私的使用の範囲を超えて複製、転載、公衆送信、改変その他の利用をすることはできないことを確認します。</p>
        <p>3. 当事務局コンテンツに関する著作権、特許権、実用新案権、商標権、意匠権、肖像権その他一切の知的財産権及びこれらの権利の登録を受ける権利（以下総称して「知的財産権」といいます。）は、当事務局、当事務局がライセンスを受けているライセンサー又は出品者に帰属し、お客様には帰属しません。また、お客様は、知的財産権の存否にかかわらず、当事務局コンテンツについて、複製、配布、転載、転送、公衆送信、改変、翻案その他の二次利用等を行ってはなりません。</p>
        <p>4. お客様が本条の規定に違反して問題が発生した場合、お客様は、自己の費用と責任において当該問題を解決するとともに、当事務局に何らの不利益、負担又は損害を与えないよう適切な措置を講じなければなりません。</p>
        <p>5. お客様は、著作物となりうる掲載内容の一部について、当事務局並びに当事務局より正当に権利を取得した第三者及び当該第三者から権利を承継した者に対し、著作者人格権（公表権、氏名表示権及び同一性保持権）を行使しません。</p>
        <h2>第 14 条（非保証・免責）</h2>
        <p>1. 本サービスの内容について、その完全性、正確性及び有効性等について、当事務局は一切の保証をしません。また、当事務局は、本サービスに中断、中止その他の障害が生じないことを保証しません。</p>
        <p>2. お客様が本サービスを利用するにあたり、本サービスから本サービスに関わる第三者が運営する他のサービス（以下「外部サービス」といいます。）に遷移する場合があります。その場合、お客様は、自らの責任と負担で外部サービスの利用規約等に同意の上、本サービス及び外部サービスを利用します。なお、外部サービスの内容について、その完全性、正確性及び有効性等について、当事務局は一切の保証をしません。</p>
        <p>3. お客様が登録情報の変更を行わなかったことにより損害を被った場合でも、当事務局は一切の責任を負いません。</p>
        <p>4. お客様は、法令の範囲内で本サービスをご利用ください。本サービスの利用に関連してお客様が日本又は外国の法令に触れた場合でも、当事務局は一切の責任を負いません。</p>
        <p>5. 予期しない不正アクセス等の行為によってお客様情報を盗取された場合でも、それによって生じるお客様の損害等に対して、当事務局は一切の責任を負いません。</p>
        <p>6. 当事務局は、天災、地変、火災、ストライキ、通商停止、戦争、内乱、感染症の流行その他の不可抗力により本契約の全部又は一部に不履行が発生した場合、一切の責任を負いません。</p>
        <p>7. 本サービスの利用に関し、お客様が他のお客様等の第三者との間でトラブル（本サービス内外を問いません。）になった場合でも、当事務局は一切の責任を負わず、お客様と当該第三者とのトラブルは、当該お客様が自らの費用と負担において解決します。</p>
        <h2>第 15 条（損害賠償責任）</h2>
        <p>1. お客様は、本規約の違反又は本サービスの利用に関連して当事務局に損害を与えた場合、当事務局に発生した損害（逸失利益及び弁護士費用を含みます。）を賠償します。</p>
        <p>2. 次項を除く本規約の他の定めにかかわらず、当事務局は、当事務局の帰責事由によりお客様に損害を与えた場合、次の各号に定める範囲でのみその損害を賠償する責任を負います。</p>
        <ul>
          <li>当事務局の故意又は重過失による場合：当該損害の全額</li>
          <li>当事務局の軽過失による場合：現実かつ直接に発生した通常の損害（特別損害、逸失利益、間接損害及び弁護士費用を除く。）の範囲内の金額</li>
        </ul>
        <p>前項にかかわらず、お客様が法人である場合又は個人が事業として若しくは事業のために本サービスを利用する場合には、当事務局に故意又は重過失のない限り、本サービスに関連して当該お客様が被った損害につき当事務局は一切の責任を負いません。</p>
        <h2>第 16条（秘密保持）</h2>
        <p>1. 本規約において「秘密情報」とは、本規約又は本サービスに関連して、お客様が、当事務局より書面、口頭若しくは記録媒体等により提供若しくは開示されたか又は知り得た、当事務局の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。ただし、以下の各号の情報については、秘密情報から除外するものとします。</p>
        <ul>
          <li>当事務局から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた又は既に知得していたもの</li>
          <li>当事務局から提供若しくは開示又は知得した後、自己の責に帰さない事由により刊行物その他により公知となったもの</li>
          <li>提供又は開示の権限のある第三者から秘密保持義務を負うことなく適法に取得したもの</li>
        </ul>
        <p>秘密情報によることなく単独で開発したもの</p>
        <p>2. お客様は、秘密情報を機密として保持し、本サービスの利用の目的のみに利用するとともに、当事務局の書面による承諾なしに第三者に当事務局の秘密情報を提供、開示又は漏洩しないものとします。</p>
        <p>3. 第二項の定めにかかわらず、お客様は、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を必要最小限の範囲で開示することができます。ただし、当該命令、要求又は要請があった場合、速やかにその旨を当事務局に通知しなければなりません。</p>
        <p>4. お客様は、秘密情報を記載した文書又は電磁的記録等を複製する場合には、事前に当事務局の書面による承諾を得ることとし、複製物の管理については第2項に準じて厳重に行うものとします。</p>
        <p>5. お客様は、当事務局から求められた場合にはいつでも、遅滞なく、当事務局の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体及びその全ての複製物の使用を中止し、返却又は廃棄しなければならないものとします。</p>
        <h2>第 17 条（個人情報の取扱い）</h2>
        <p>個人情報については、当事務局が別途定める「プライバシーポリシー」に則り、適正に取り扱うこととします。</p>
        <h2>第 18条（通知又は連絡）</h2>
        <p>1. お客様と当事務局との間の通知又は連絡は、当事務局の定める方法によって行うものとします。</p>
        <p>2. 当事務局は、お客様から、当事務局が別途定める方式に従った変更届出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知又は連絡を行います。</p>
        <p>3. 前項の変更届出がなされてないことにより当事務局からの通知が遅延又は不着となった場合であっても、これらは、通常到達すべき時にお客様へ到達したものとみなします。</p>
        <h2>第 19 条（権利義務等の譲渡等）</h2>
        <p>1. お客様は、本規約等について、本規約等上の地位及びこれらに基づく一切の権利義務を、当事務局の書面による事前の承諾なく、第三者に譲渡、貸与、又は担保に供することはできません。</p>
        <p>2. 当事務局は、本サービスに関する事業を合併、会社分割、事業譲渡その他の事由により第三者に承継させる場合には、当該事業の承継に伴い、本規約等上の地位、本規約等に基づく権利義務及びお客様情報を当該事業の承継人に譲渡することができるものとし、お客様は、かかる譲渡について本項において予め同意したものとします。</p>
        <h2>第 20条（分離可能性）</h2>
        <p>1. 本規約の規定の一部が法令に基づいて無効と判断されても、本規約の他の規定は有効とします。</p>
        <p>2. 本規約の規定の一部があるお客様との関係で無効又は取消となった場合でも、本規約は他のお客様との関係では有効とします。</p>
        <h2>第 21条（存続規定）</h2>
        <p>第7条から第16条、第22条、第23条までの規定は、本契約の終了後も有効に存続するものとします。</p>
        <h2>第 22条（準拠法、管轄裁判所）</h2>
        <p>1. 本規約の有効性、解釈及び履行については、日本法に準拠し、日本法に従って解釈されるものとします。</p>
        <p>2. 当事務局とお客様との間での論議・訴訟その他一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を専属的合意管轄裁判所とします｡</p>
        <h2>第 23条（協議解決）</h2>
        <p>当事務局及びお客様は、本規約等に定めのない事項又は本規約等の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。</p>
        <p>2022年 2月 28日: 施行</p>
      </div>
      <Footer />
      <FooterMenu />
    </>
  )
}