import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Link } from '@aws-amplify/ui-react'
import { messages } from '../../config/message'
import { deleteRoom } from '../../datastores/room'
import { getUserWithPass, updateUser } from '../../datastores/user'
import ButtonBlack from '../atoms/ButtonBlack'
import ButtonClose from '../atoms/ButtonClose'
import InputGray from '../atoms/InputGray'
import ToggleBlue from '../atoms/ToggleBlue'
import InputIcon from '../molecules/InputIcon'
import Passcode from '../molecules/Passcode'
import AdSenseSquare from '../AdSense/AdSenseSquare';
import { getRoomToken, updateToken } from '../../datastores/token'
import { getRoomUsers } from '../../datastores/user'
import { formatDateTime } from '../../modules/moment'
import './userSetting.scss'

export default function UserSetting(props) {
  const { user, roomId, backToTalkRoomAction, setUserAction } = props
  const [nickname, setNickname] = useState(user.nickname)
  const [usePass, setUsePass] = useState(user.hasPassword)
  const [goNext, setGoNext] = useState(false)
  const [currentPasscord, setCurrentPasscord] = useState('')
  const [newPasscord, setNewPasscord] = useState('')
  const [confirmPasscord, setConfirmPasscord] = useState('')
  const [usePush, setUsePush] = useState(user.pushNotification)
  const [usePassOn, setUsePassOn] = useState(false)
  const [nicknameError, setNicknameError] = useState('')
  const [currentPasscordError, setCurrentPasscordError] = useState('')
  const [newPasscordError, setNewPasscordError] = useState('')
  const [confirmPasscordError, setConfirmPasscordError] = useState('')
  const [token, setToken] = useState()
  const [expiredAt, setExpiredAt] = useState()
  const [isInvitationCodePublished,setIsInvitationCodePublished] = useState(false)
  // TODO セキュリティー通知は一旦なし
  // const [securityNotification, setSecurityNotification] = useState(false)
  // const [email, setEmail] = useState(user.email)
  const [isSaving, setIsSaving] = useState(false)
  const [saveSuccessful, setSaveSuccessful] = useState(false)

  const clickSubmit = (e) => {
    e.preventDefault()
    if (nicknameError !== '') return
    document.getElementById('userSettingSubmitButton').click()
  }

  const userSettingSubmit = async (e) => {
    e.preventDefault();
    if (nicknameError !== '') return;

    setIsSaving(true);
    
    const updatedUser = await updateUser(user, nickname, user.hasPassword, user.password, user.pushNotification);
    setUserAction({
      ...updatedUser,
      nickname,
    });

    setIsSaving(false);
    setSaveSuccessful(true);
    setTimeout(() => setSaveSuccessful(false), 2000);
  }

  const checkPasscord = async (passcord) => {
    const res = await getUserWithPass(user.userId, passcord);
    return res.length > 0;
  }

  const changeUsePass = async (value) => {
    if (value) {
      setUsePass(value);
      setGoNext(true);
    } else {
      try {
        const updatedUser = await updateUser(user, user.nickname, false, '', user.pushNotification);
        setUserAction(updatedUser);
        setCurrentPasscord('');
        setNewPasscord('');
        setConfirmPasscord('');
        setUsePass(value);
        setGoNext(false);
      } catch (error) {
        console.error('Error in changeUsePass:', error);
      }
    }
  }

  const handlePasscordSubmit = async () => {
    const correct = !user.hasPassword || await checkPasscord(currentPasscord);
    if (correct && newPasscord === confirmPasscord) {
      try {
        const updatedUser = await updateUser(user, user.nickname, true, newPasscord, user.pushNotification);
        setUserAction(updatedUser);
        setCurrentPasscord('');
        setNewPasscord('');
        setConfirmPasscord('');
        setGoNext(false);
      } catch (error) {
        console.error('Error in handlePasscordSubmit:', error);
      }
    } else {
      setConfirmPasscordError(messages.userSetting.error.updateConfirm);
    }
  }

  useEffect(() => {
    const submitPasscord = async () => {
      if (usePass && confirmPasscord.length === 6 && confirmPasscordError === '') {
        await handlePasscordSubmit();
      }
    };
    submitPasscord();
  }, [confirmPasscord, confirmPasscordError]);

  // const changeUsePush = async (value) => {
  //   try {
  //     const updatedUser = await updateUser(user, user.nickname, user.hasPassword, user.password, value);
  //     setUserAction(updatedUser);
  //     setUsePush(value);
  //   } catch (error) {
  //     console.error('Error in changeUsePush:', error);
  //   }
  // }

  const deleteRoomAction = () => {
    const result = window.confirm('トークルームを削除してよろしいですか？')

    if (result) {
      deleteRoom(roomId).then((res) => {
        window.location.reload()
      }).catch((err) => {
        // TODO ログ出力
      })
    }
  }

  const publishToken = async () => {
    let tokens = await getRoomToken(roomId);
    
    if (tokens.length > 0) {
      setToken(tokens[0].token);
    } else {
      const updatedToken = await updateToken(roomId);
      setToken(updatedToken.token);
    }

    setIsInvitationCodePublished(true);
  };

  useEffect(() => {
    if (!usePass) {
      setCurrentPasscord('')
      setNewPasscord('')
      setConfirmPasscord('')
      setCurrentPasscordError('')
      setNewPasscordError('')
      setConfirmPasscordError('')
    }
  }, [usePass])

  useEffect(() => {
    setNicknameError(nickname !== '' ? '' : messages.userSetting.error.nickname)
  }, [nickname])

  useEffect(() => {
    if (user.hasPassword && usePassOn && currentPasscord.length < 6) {
      setCurrentPasscordError(messages.userSetting.error.currentPasscord)
    } else {
      setCurrentPasscordError('')
    }
  }, [usePassOn, currentPasscord])

  useEffect(() => {
    if (usePassOn && newPasscord.length < 6) {
      setNewPasscordError(messages.userSetting.error.newPasscord)
    } else {
      setNewPasscordError('')
    }
  }, [usePassOn, newPasscord])

  useEffect(() => {
    if (usePassOn && confirmPasscord.length < 6) {
      setConfirmPasscordError(messages.userSetting.error.confirmPasscord)
    } else if (usePassOn && newPasscord !== confirmPasscord) {
      setConfirmPasscordError(messages.userSetting.error.updateConfirm)
    } else {
      setConfirmPasscordError('')
    }
  }, [usePassOn, confirmPasscord])

  useEffect(() => {
    if (usePass && confirmPasscord.length === 6 && confirmPasscordError === '')
      handlePasscordSubmit();
  }, [confirmPasscordError])

  useEffect(() => {
    getRoomToken(roomId).then((res) => {
      if(res.length > 0) {
        setToken(res[0].token)
        setExpiredAt(formatDateTime(res[0].expiredAt))
      }
    }).catch((err) => {
      // TODO ログ出力
    })

    getRoomUsers(roomId).then((res) => {
      // setUserId(res[0].id)
    }).catch((err) => {
      // TODO ログ出力
    })
  },[])

  return (
    <div className="settingArea">
      <form onSubmit={userSettingSubmit}>
        {!goNext &&
          <>
            <div className="buttonClose">
              <ButtonClose clickAction={backToTalkRoomAction} />
            </div>

            <InputGray title={messages.userSetting.nickname} value={nickname} changeAction={setNickname} />
            <ButtonBlack
              type='button'
              value={isSaving ? '保存中...' : '保存'}
              onClick={(e) => clickSubmit(e)}
              disabled={isSaving}
              className={isSaving ? 'button--clicked' : ''}
            />
            {saveSuccessful && <p>設定が保存されました。</p>}

            <div className="checkList">
              <ToggleBlue 
                label={messages.userSetting.usePass}
                checked={usePass}
                changeAction={changeUsePass} />
            </div>
            
            {isInvitationCodePublished ?
              <InputGray title='招待コード' value={token} className='invitationCode' /> :
              <ButtonBlack value='招待コード生成' onClick={publishToken} className='createInvitationCodeButton'/>
            }
            <Link onClick={deleteRoomAction} >トークルームを削除する</Link>

            <AdSenseSquare />
          </>
        }
        {goNext && usePass && user.hasPassword && currentPasscord.length < 6 &&
          <Passcode title={messages.userSetting.currentPasscord} value={currentPasscord} changeAction={setCurrentPasscord} error={currentPasscordError} />
        }
        {goNext && usePass && (currentPasscord.length === 6 || !user.hasPassword) && newPasscord.length < 6 &&
          <Passcode title={messages.userSetting.newPasscord} value={newPasscord} changeAction={setNewPasscord} error={newPasscordError} />
        }
        {goNext && usePass && newPasscord.length === 6 &&
          <Passcode title={messages.userSetting.confirmPasscord} value={confirmPasscord} changeAction={setConfirmPasscord} error={confirmPasscordError} />
        }
        <button id="userSettingSubmitButton" type="submit" hidden />
      </form>
    </div>
  )
}
