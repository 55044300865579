import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../config/routing'
import { formatDateTime } from '../../modules/moment'
import { getRoomWithLatestTalk } from '../../datastores/room'
import './roomListItem.scss'

export default function RoomListItem(props) {
  const { userId, roomId } = props
  const [room, setRoom] = useState(undefined)
  const [latestTalk, setLatestTalk] = useState([])
  const [unread, setUnread] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const result = await getRoomWithLatestTalk(roomId, userId)
      setRoom(result.room)
      setLatestTalk(result.latest)
      setUnread(result.unread)
    }
    
    fetchData();
  }, [])

  return (
    <>
      {room !== undefined &&
        <Link className="roomListItem" to={`${routes.room}/${userId}`}>
          <p className="name">{room.name}</p>
          <div className="rightContent">
            {latestTalk &&
              <>
                <p className="lastDate">{formatDateTime(latestTalk.sendAt)}</p>
                {unread &&
                  <p className="unread">未読</p>
                }
              </>
            }
          </div>
        </Link>
      }
    </>
  )
}
