import React from 'react'
import { routes } from '../../config/routing'
import FooterMenuItem from '../atoms/FooterMenuItem'
import './footerMenu.scss'

export default function FooterMenu(props) {
  return (
    <footer className="footerMenu">
      <FooterMenuItem url={routes.top} icon="icon_home.svg" name="ホーム" />
      <FooterMenuItem url={routes.newRoom} icon="icon_add.svg" name="新規作成" />
      <FooterMenuItem url={routes.roomList} icon="icon_list.svg" name="トーク履歴" />
      {/*
      <FooterMenuItem url="" icon="icon_config.svg" name="設定" />
      */}
    </footer>
  )
}
