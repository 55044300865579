// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Read, Talk, Token, User, FCMToken, Room } = initSchema(schema);

export {
  Read,
  Talk,
  Token,
  User,
  FCMToken,
  Room
};