import React from 'react'
import { Image, Link } from '@aws-amplify/ui-react'
import { routes } from '../../config/routing'
import ButtonBlack from '../atoms/ButtonBlack'
import AdSenseSquare from '../AdSense/AdSenseSquare';
import './error.scss'

export default function Error(props) {
  const { error } = props

  return (
    <div className="errorArea">
      <Image src={error.image} />
      <p className="errorAreaText">{error.text}</p>
      <Link href={routes.top}>
        <ButtonBlack value='HOME' />
      </Link>
      <AdSenseSquare />
    </div>
  )
}
