import React from 'react'
import './faqElement.scss'

export default function FaqElement(props) {
  const { q, a } = props

  return (
    <div className="faqElement">
      <p className="question"><span>Q.</span>{q}</p>
      <p className="answer">{a}</p>
    </div>
  )
}
