import React, { useState, useEffect } from 'react'
import { messages } from '../../config/message'
import { getUserWithPass } from '../../datastores/user'
import './inputPasscord.scss'
import Passcode from './Passcode'

export default function InputPasscord(props) {
  const { userId, setIsValidPassword } = props
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const inputPasscordAction = async (e) => {
    e.preventDefault()

    const res = await getUserWithPass(userId, password)

    if (res.length > 0) {
      setError('')
      setIsValidPassword()
    } else {
      setError(messages.room.error.invalidPasscord)
    }
  }

  useEffect(() => {
    if (password.length === 6)
      document.getElementById('inputPasscordSubmitButton').click()
  }, [password])

  return (
      <form className="inputPasscord" onSubmit={inputPasscordAction}>
        <Passcode title='パスコードを入力' value={password} changeAction={setPassword} error={error} />
        <button id="inputPasscordSubmitButton" type="submit" hidden />
      </form>
  )
}
