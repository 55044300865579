import moment from "moment"
import { DataStore } from 'aws-amplify'
import { Read } from '../models'

// export const getLatestReads = async (userId, talkIds) => {
//   for (let i = talkIds.length - 1; i >= 0; i--) {
//     let read = await DataStore.query(
//       Read,
//       c => c.userID('eq', userId).talkID('eq', talkIds[i])
//     )

//     if (read.length >= 1) return read[0].talkID
//   }
// }

export const getReads = async (userId, talkId) => {
  return await DataStore.query(
    Read,
    c => c.userID('eq', userId).talkID('eq', talkId)
  )
}

export const insertReads = async (userId, roomId, talkIds, index = 0) => {
  for (let i = index; i < talkIds.length; i++) {
    let read = await DataStore.query(
      Read,
      c => c.userID('eq', userId).roomID('eq', roomId).talkID('eq', talkIds[i])
    )

    if (read.length > 0) continue

    DataStore.save(
      new Read({
        userID: userId,
        roomID: roomId,
        talkID: talkIds[i],
        readAt: moment().format()
      })
    )
  }
}

export const getLastRead = async (userId, roomId) => {
  let reads = await DataStore.query(
    Read,
    c => c.userID('eq', userId).roomID('eq', roomId)
  )

  return reads.length >= 1 ? reads[0] : null
}

export const updateLastRead = async (userId, roomId, talkId) => {
  let read = await getLastRead(userId, roomId)

  if (read === null) {
    // If no existing record, create a new one
    const newRead = new Read({
      userID: userId,
      roomID: roomId,
      talkID: talkId,
      readAt: moment().format()
    })
    await DataStore.save(newRead)
  } else {
    // If existing record, update it
    const updatedRead = Read.copyOf(read, updated => {
      updated.talkID = talkId;
      updated.readAt = moment().format();
    });
    await DataStore.save(updatedRead)
  }
}
