import React from 'react'
import { Link, Image } from '@aws-amplify/ui-react'
import './snsLink.scss'
import { messages } from '../../config/message'

export default function SnsLink(props) {
  const { url, src, text } = props

  const sendInvitationHandler = () => {
    const shareData = {
      title: 'Txtbase｜使い捨てできる無料のメッセンジャー',
      text: messages.shareMessage,
      url: 'https://txtbase.net/',
    }
    try {
      navigator.share(shareData);
    } catch(err) {
      const address = '';
      window.location.href = 'mailto:' + address + '?subject=' + shareData.title + '&body=' + shareData.text;
    }
  }
  return (
    <div className="snsItem">
      <Link href={url === '' ? null : url} isExternal={true}  >
        <div className="snsIcon">
          <Image src={src} onClick={text=== 'More' ? sendInvitationHandler : null}/>
        </div>
        <p>{text}</p>
      </Link>
    </div>
  )
}
