import React from 'react'
import { messages } from '../../config/message'
import FaqElement from '../molecules/FaqElement'
import './faq.scss'

export default function Faq(props) {
  return (
    <div className="faqArea">
      <h2>よくある質問</h2>
      {messages.faq.map((f, i) =>
        <FaqElement key={i} q={f.q} a={f.a} />
      )}
      {/* TODO ベータ版終了後削除
      <p>
        ※よくある質問の作成にあたり、ユーザー様の率直な疑問を募集いたします。
        <Link href='https://forms.gle/x455oKuxaj3gKre4A' isExternal={true}>専用フォーム</Link>よりご意見をお寄せください。
      </p>
       */}
    </div>
  )
}
