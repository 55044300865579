import React from 'react'
import './share.scss'
import SnsLink from '../molecules/SnsLink'
import { messages } from '../../config/message'
import isMobileDevice from '../../modules/isMobile';

export default function Share(props) {
  return (
    <div className="snsArea">
      <h3>Txtbaseを友だちに教える</h3>
      <div className="snsItems">
        {messages.shares.map((s, i) => {
          if(i !== messages.shares.length -1 || isMobileDevice()){
            return <SnsLink key={i} url={s.url} src={s.src} text={s.text} />
          }
        }
        )}
      </div>
    </div>
  )
}
