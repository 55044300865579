import React from 'react'
import { reformatContents, reformatFile } from '../../modules/talk'
import './selfTalk.scss'

export default function SelfTalk(props) {
  const { talkId, talk, file, fileType, presignedUrl, sendAt } = props

  if (talk)
    return (
      <div className="chatRight" data-talk={talkId}>
        <p className="chatSub">{sendAt}</p>
        <div className="chatItem">
          <div className="chatBox">
            {talk.split('\n').map((s, index) => <div key={index}>{reformatContents(s)}</div>)}
          </div>
        </div>
      </div>
    )

  if (file)
    return (
      <div className="chatRight chatFile" data-talk={talkId}>
        <p className="chatSub">{sendAt}</p>
        {reformatFile(file, fileType, presignedUrl)}
      </div>
    )
}
