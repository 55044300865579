import React, { useState, useEffect } from 'react'
import { Link } from '@aws-amplify/ui-react'
import moment from 'moment'
import { routes } from '../../config/routing'
import { messages } from '../../config/message'
import { getValidToken } from '../../datastores/token'
import InputWhite from '../atoms/InputWhite'
import ButtonBlack from '../atoms/ButtonBlack'
import Recaptcha from '../atoms/Recaptcha'

export default function InviteCodeInput() {
  const [inviteCode, setInviteCode] = useState('');
  const [inviteCodeError, setInviteCodeError] = useState(undefined);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSubmitDisabled(!recaptchaVerified || inviteCode.trim() === '' || isLoading); // isLoadingを評価するように修正
  }, [recaptchaVerified, inviteCode, isLoading]);

  const sendInputCode = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      let res = await getValidToken(inviteCode, 2, 2000);

      if (res && res.length === 1) {
        window.location.href = `${routes.inviteRoom}/${inviteCode}`;
      } else {
        setInviteCodeError(messages.inviteCodeError);
      }
    } catch (err) {
      setInviteCodeError(messages.inviteCodeError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={sendInputCode}>
        <p className="mainCopy">Welcome!</p>
        <p>招待コードを入力してトークを始める</p>
        <InputWhite value={inviteCode} changeAction={setInviteCode} error={inviteCodeError} />
        <Recaptcha setRecaptchaVerified={setRecaptchaVerified} />
        <ButtonBlack type='submit' value='NEXT' disabled={submitDisabled} />
      </form>
      <p>
        ※現在、ベータ版として試運用しております。機能実装の参考にさせていただきますので、
        <Link href='https://forms.gle/LRht9Ha5EYnbyGvf6' isExternal={true}>専用フォーム</Link>よりご意見をお寄せください。
      </p>
    </div>
  )
}
