import React, { useState, useEffect } from 'react';
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'
import InviteCodeInput from '../organisms/InviteCodeInput'
import Share from '../organisms/Share'
import Features from '../organisms/Features'
import Faq from '../organisms/Faq'
import AddToHome from '../organisms/AddToHome'
import FooterMenu from '../organisms/FooterMenu'
import './top.scss'
import { isPWA } from '../../modules/isPwa.js';
import isMobileDevice from '../../modules/isMobile.js';

export default function Top(props) {

  const isMobile = isMobileDevice();
  const isPwa = isPWA();

  return (
    <>
      <Header />
      <div className="top">
        <div className="mainArea">
          <InviteCodeInput />
        </div>
        <Features />
        <Share />
        <Faq />
      </div>
      <Footer />
      {!isPwa && isMobile && <AddToHome />}
      <FooterMenu />
    </>
  )
}