import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { routes } from '../../config/routing'
import './footer.scss'

export default function Footer(props) {
  return (
    <div className="footerLink">
      <div className="footerItem">
        <RouterLink to={routes.rule}>規約</RouterLink>
        <span>|</span>
        <RouterLink to={routes.privacy}>プライバシー</RouterLink>
        <span>|</span>
        <RouterLink to={routes.about}>運営</RouterLink>
      </div>
      <p>Copyright ©Txtbase All rights reserved.</p>
    </div>
  )
}
