import moment from 'moment'

export const formatDateTimeForPrefix = (timestamp) => {
  return moment(timestamp).format('YYYY-MM-DD-HH-mm-ss')
}

export const formatDateTime = (timestamp) => {
  return moment(timestamp).format('YYYY/M/D H:mm')
}

export const formatDate = (timestamp) => {
  return moment(timestamp).format('YYYY/M/D')
}

export const formatDateToMD = (timestamp) => {
  return moment(timestamp).format('M/D')
}

export const formatTimeToHm = (timestamp) => {
  return moment(timestamp).format('H:mm')
}
