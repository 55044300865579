import React from 'react'
import ReactDOM from 'react-dom'
import { Amplify, ServiceWorker } from 'aws-amplify'
import { AmplifyProvider } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import awsconfig from './aws-exports'
import reportWebVitals from './reportWebVitals'
import App from './App'
import ErrorBoundary from './modules/errorBoundary'
import './index.css'
import './components/_base.scss';

Amplify.configure(awsconfig)

ReactDOM.render(
  <React.StrictMode>
    <AmplifyProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

const serviceWorker = new ServiceWorker()
serviceWorker.register('/serviceWorker.js', '/')
