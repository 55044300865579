import React from 'react'
import './headerRoomList.scss'

export default function HeaderRoomList(props) {
  return (
    <header className="headerRoomList">
      <div>
        <p>トーク履歴</p>
      </div>
      {/* TODO 検索フォームの実装 */}
    </header>
  )
}
