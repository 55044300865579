import React from 'react'
import { messages } from '../../config/message'
import Header from '../organisms/Header'
import Error from '../organisms/Error'
import FooterMenu from '../organisms/FooterMenu'
import './404.scss'

export default function NotFoundPage(props) {
  return (
    <>
      <Header />
      <Error error={messages.errors.notFound} />
      <FooterMenu />
    </>
  )
}
