import React from 'react'
import { Image } from '@aws-amplify/ui-react'
import { useNavigate } from "react-router-dom";
import ButtonBack from '../atoms/ButtonBack'
import './headerRoom.scss'

export default function HeaderRoom(props) {
  const { name, showUserSettingAction } = props

  const navigate = useNavigate();

  return (
    <>
      <header className="headerRoom">
        <div className="button">
          <ButtonBack clickAction={() => navigate("/room_list")} />
        </div>
        <p>{name}</p>
        <button onClick={showUserSettingAction}>
          <Image src='/images/icon_dot.svg' />
        </button>
      </header>
    </>
  )
}
