import moment from 'moment'
import { DataStore } from 'aws-amplify'
import { Token } from '../models'

export const getValidToken = async (token, maxRetries = 2, retryDelay = 1000) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      const result = await DataStore.query(
        Token, c => c.token('eq', token).expiredAt('gt', moment().format())
      )
      
      if (result.length > 0) {
        return result;
      } else if (i < maxRetries - 1) {
        await new Promise(resolve => setTimeout(resolve, retryDelay));
      } else {
        throw new Error('No valid token found after retries');
      }
    } catch (err) {
      if (i < maxRetries - 1) {
        await new Promise(resolve => setTimeout(resolve, retryDelay));
      } else {
        throw err;
      }
    }
  }
}

export const getRoomToken = async (roomId) => {
  return await DataStore.query(
    Token, c => c.roomID('eq', roomId).expiredAt('gt', moment().format())
  )
}

export const createToken = async (roomId) => {
  try {
    return await DataStore.save(
      new Token({
        roomID: roomId,
        token: Math.random().toString(32).substring(2),
        expiredAt: moment().add(1, 'day').format(),
      })
    )
  } catch (error) {
    console.error('Failed to create token: ', error);
    return null;
  }
}

export const updateToken = async (roomId) => {
  const originalToken = await DataStore.query(Token, t => t.roomID('eq', roomId));

  return await DataStore.save(
    Token.copyOf(originalToken[0], updated => {
      updated.token = Math.random().toString(32).substring(2);
      updated.expiredAt = moment().add(1, 'day').format();
    })
  )
}
