import { DataStore } from 'aws-amplify'
import { FCMToken } from '../models'

export const createToken = async (userId, roomId, fcmToken) => {
  return await DataStore.save(
    new FCMToken({
      userID: userId,
      roomID: roomId,
      fcmToken: fcmToken
    })
  )
}

export const getItem = async (userId, roomId, tokenId) => {
  try {
    const registeredToken = await DataStore.query(FCMToken, c => c.fcmToken("eq", tokenId).userID("eq", userId).roomID("eq", roomId));
    return registeredToken
  } catch (err) {
    console.log("[ERROR] Error query record by id:", err)
    return [];
  }
}
