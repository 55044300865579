import React from 'react'
import { SwitchField } from '@aws-amplify/ui-react'
import './toggleBlue.scss'

export default function ToggleBlue(props) {
  const { label, checked, changeAction } = props

  return (
    <div>
      <SwitchField
        label={label}
        defaultChecked={checked}
        onChange={() => changeAction(!checked)} />
    </div>
  )
}
