// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Define Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID
};

// Initialize Firebase
initializeApp(firebaseConfig);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const messaging = getMessaging();

export const getNotificationToken = () => {
return getToken(messaging, {vapidKey: process.env.REACT_APP_FCM_VAPID_KEY})
  .catch(error => {
    console.error("Error in getNotificationToken: ", error);
    // Optional: re-throw the error if you want it to propagate:
    throw error;
  });
}

export const onReceiveMessage = (me) => {
  try {
    onMessage(messaging, (payload) => {
      const isDifferentSender = me.id !== payload.data['gcm.notification.from'];

      // Add debug log here
      console.log("Comparison result of 'me.id' and sender ID:", isDifferentSender);

      if (isDifferentSender) {
        // Additional debug log
        console.log("Notification will be shown. 'me.id':", me.id, "Sender ID:", payload.data['gcm.notification.from']);

        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(payload.notification.title)
        })
      }
    });
  } catch (error) {
    throw error;
  }
}
