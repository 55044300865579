import React, { useState } from 'react';
import { messages } from '../../config/message';
import './addToHome.scss';

export default function AddToHome() {
  const [showPopup, setShowPopup] = useState(false);

  const handleAddToHomeClick = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <button onClick={handleAddToHomeClick} className="addToHome">
        <p>{messages.addToHome[0].copy}</p>
        <p><span>{messages.addToHome[0].subCopy}</span></p>
      </button>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <div className="close-btn" onClick={closePopup}></div>
            <p>ホーム画面に追加する手順</p>
            <ol>
              <li>ブラウザのシェアボタンをタップ</li>
              <li>「ホーム画面に追加」を選択</li>
              <li>完了</li>
            </ol>
          </div>
        </div>
      )}
    </>
  );
}
