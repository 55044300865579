import React from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import './recaptcha.scss'

export default function Recaptcha({ setRecaptchaVerified }) {
  return (
    <div className="recaptcha">
      <GoogleReCaptcha onVerify={() => setRecaptchaVerified(true)} />
    </div>
  )
}
