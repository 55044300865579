import React, { useEffect, useState } from 'react'
import { messages } from '../../config/message'
import { updateUserByFirstSetting } from '../../datastores/user'
import Passcode from '../molecules/Passcode'
import InputWhite from '../atoms/InputWhite'
import ButtonBlack from '../atoms/ButtonBlack'
import ToggleBlue from '../atoms/ToggleBlue'
import Header from '../organisms/Header'
import FooterMenu from '../organisms/FooterMenu'
import AdSenseSquare from '../AdSense/AdSenseSquare';
import './firstAccessSetting.scss'

export default function FirstAccessSetting(props) {
  const { user, setIsFirstAccess } = props
  const [nickname, setNickname] = useState(user.nickname)
  const [usePass, setUsePass] = useState(user.hasPassword)
  const [usePush, setUsePush] = useState(user.pushNotification)
  const [goNext, setGoNext] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [nicknameError, setNicknameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [passwordConfirmError, setPasswordConfirmError] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const firstSetting = async (e) => {
    e.preventDefault()

    if (usePass && (passwordError !== '' || passwordConfirmError !== '')) return

    try {
      const updatedUser = await updateUserByFirstSetting(user, nickname, usePass, password, usePush);
      if (updatedUser) {
        setIsFirstAccess(updatedUser.isFirstAccess);
      } else {
        // TODO: error handling
      }
    } catch (err) {
      console.log(err);
      // TODO: log the error properly
    }
  }

  const clickNext = (e) => {
    e.preventDefault()

    if (nickname === '') {
      setNicknameError(messages.userSetting.error.nickname)
      return
    }

    if (!usePass) {
      document.getElementById('firstSettingSubmitButton').click()
      return
    }

    setGoNext(true)
  }

  const backToPassword = (e) => {
    e.preventDefault()
    setPassword('')
    setPasswordConfirm('')
    setPasswordError('')
    setPasswordConfirmError('')
  }

  useEffect(() => {
    setSubmitDisabled(nickname.trim() === '');
  }, [nickname]);

  useEffect(() => {
    if (usePass) {
      setPasswordError(password !== '' ? '' : messages.userSetting.error.password)
      setPasswordConfirmError(password === passwordConfirm ? '' : messages.userSetting.error.confirm )
      setPasswordConfirmError(passwordConfirm !== '' ? '' : messages.userSetting.error.passwordConfirm)
    } else {
      setPassword('')
      setPasswordConfirm('')
      setPasswordError('')
      setPasswordConfirmError('')
    }
  }, [usePass])

  useEffect(() => {
    if (nickname !== '') {
      setNicknameError('')
    }
  }, [nickname])

  useEffect(() => {
    setPasswordError(password !== '' ? '' : messages.userSetting.error.password)
  }, [password])

  useEffect(() => {
    if (passwordConfirm === '') {
      setPasswordConfirmError(messages.userSetting.error.passwordConfirm)
    } else if (password !== passwordConfirm) {
      setPasswordConfirmError(messages.userSetting.error.confirm)
    } else {
      setPasswordConfirmError('')
    }
  }, [passwordConfirm])

  useEffect(() => {
    if (passwordConfirm.length === 6 && passwordConfirmError === '')
      document.getElementById('firstSettingSubmitButton').click()
  }, [passwordConfirmError])

  return (
    <div className="inputArea">
      <form onSubmit={firstSetting}>
        {!goNext &&
          <>
          <Header />
          <div className="firstRoomArea">
            <InputWhite title={messages.userSetting.nickname} value={nickname} changeAction={setNickname}
                        error={nicknameError} />
            <ToggleBlue label={messages.userSetting.usePass} checked={usePass} changeAction={setUsePass} />
            <ButtonBlack type='button' value='NEXT' onClick={(e) => clickNext(e)} disabled={submitDisabled}/>
            <AdSenseSquare />
          </div>
          <FooterMenu />
          </>
        }
        {goNext && password.length < 6 &&
            <Passcode title={messages.userSetting.passcord} value={password} changeAction={setPassword} error={passwordError} />
        }
        {goNext && password.length === 6 &&
          <>
          <Passcode title={messages.userSetting.passcordConfirm} value={passwordConfirm} changeAction={setPasswordConfirm} error={passwordConfirmError} />
          <button type='button' onClick={(e) => backToPassword(e)}>back</button> 
          </>
        }
        <button id="firstSettingSubmitButton" type="submit" hidden />
      </form>
    </div>
  )
}
