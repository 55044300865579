import React from 'react'
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'
import FooterMenu from '../organisms/FooterMenu'
import './about.scss'

export default function About(props) {
  return (
    <>
      <Header />
      <div className="about fixedPageArea">
        <h1>運営情報</h1>
        <div className="about_element">
          <p className="title">名前</p>
          <p>Txtbase事務局</p>
        </div>
        <div className="about_element">
          <p className="title">所在地</p>
          <p>東京都渋谷区</p>
        </div>
        <div className="about_element">
          <p className="title">お問い合わせ</p>
          <p>info@txtbase.net</p>
        </div>
      </div>
      <Footer />
      <FooterMenu />
    </>
  )
}