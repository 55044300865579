import React from 'react'
import './inputGray.scss'
import TxtFormError from './TxtFormError'

export default function InputGray(props) {
  const { title=undefined, value='', changeAction=undefined, error=undefined, className=undefined } = props
  return (
    <div className="inputArea">
      {title &&
        <label className={className}>{title}</label>
      }
      <input className='inputGray' value={value} onChange={(e) => changeAction(e.target.value)}/>
      {error &&
        <TxtFormError error={error} />
      }
    </div>
  )
}
