import React from 'react'
import { Image } from '@aws-amplify/ui-react'
import './buttonBack.scss'

export default function ButtonBack(props) {
  const { clickAction } = props

  return (
    <button onClick={clickAction}>
      <Image src='/images/icon_back.svg' />
    </button>
  )
}
