import React from 'react'
import { Image } from '@aws-amplify/ui-react'
import { Link } from 'react-router-dom'
import './footerMenuItem.scss'

export default function FooterMenuItem(props) {
  const { url, icon, name } = props

  return (
    <Link to={url} className="footerMenuItem">
      <div>
        <Image src={`/images/${icon}`}></Image>
      </div>
      <p>{name}</p>
    </Link>
  )
}
