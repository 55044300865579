import React from 'react'
import { messages } from '../../config/message'
import Header from '../organisms/Header'
import Error from '../organisms/Error'
import FooterMenu from '../organisms/FooterMenu'

export default function ErrorPage(props) {
  return (
    <>
      <Header />
      <Error error={messages.errors.error} />
      <FooterMenu />
    </>
  )
}
