import { useEffect } from 'react';

const useHandleResize = (setVh) => {
  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    var elements = document.getElementsByClassName('App');
    Array.prototype.forEach.call(elements, function(element) {
        const originalHeight = element.style.height;
        const observer = new MutationObserver(function (mutations, observer) {
            element.style.height = originalHeight;
        });
        observer.observe(element, {
            attributes: true,
            attributeFilter: ['style']
        });
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setVh]);
}

export default useHandleResize;
