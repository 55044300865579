import React from 'react'
import { Image } from '@aws-amplify/ui-react'
import './featureImage.scss'

export default function FeatureImage(props) {
  const { text, src } = props
  return (
    <div className="featureItem">
      <pre className="featureCaption">{text}</pre>
      <Image src={src} />
    </div>
  )
}
