import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { routes } from '../../config/routing'
import { formatDateTime } from '../../modules/moment'
import { copyToClipboard } from '../../modules/clipboard';
import { getRoomToken } from '../../datastores/token'
import { getRoomUsers } from '../../datastores/user'
import ButtonBlack from '../atoms/ButtonBlack'
import ButtonBorderBlack from '../atoms/ButtonBorderBlack'
import InputWhite from '../atoms/InputWhite'
import Loader from '../atoms/Loader'
import Header from '../organisms/Header'
import FooterMenu from '../organisms/FooterMenu'
import AdSenseSquare from '../AdSense/AdSenseSquare'
import './createRoom.scss'

export default function CreateRoom(props) {
  
  const { roomId } = useParams()
  const [userId, setUserId] = useState()
  const [token, setToken] = useState()
  const [expiredAt, setExpiredAt] = useState()

  useEffect(() => {
    getRoomToken(roomId).then((res) => {
      setToken(res[0].token)
      setExpiredAt(formatDateTime(res[0].expiredAt))
    }).catch((err) => {
      // TODO ログ出力
    })

    getRoomUsers(roomId).then((res) => {
      setUserId(res[0].id)
    }).catch((err) => {
      // TODO ログ出力
    })
  }, [])

  const sendInvitationHandler = () => {
    const bodyText = `新しいトークルームに招待されました。\n\n招待コード:\n${token}\n\n`;
    const shareData = {
      title: 'Txtbase｜使い捨てできる無料のメッセンジャー',
      text: bodyText,
      url: 'https://txtbase.net',
    };
    try {
      navigator.share(shareData);
    } catch(err) {
      const address = '';
      window.location.href = 'mailto:' + address + '?subject=' + shareData.title + '&body=' + encodeURIComponent(shareData.text);
    }
  }

  return (
    <>
      <Header />
      {
        !token || !userId || !expiredAt ? (
          <Loader />
        ) : (
          <div className="createRoomArea">
            <p className="createRoomMessage">トークルームが作成されました。</p>
            <div className="roomBtnBox">
              <Link to={`${routes.room}/${userId}`}>
                <ButtonBlack value='トークルーム' />
              </Link>
              <ButtonBorderBlack value='招待の送信' onClick={sendInvitationHandler} />
              <InputWhite value={token} onClick={() => copyToClipboard(token)} />
            </div>
            <div>
              <p>招待コードの有効期限は24時間です。</p>
              {/* TODO 有効期限の表示形式修正 */}
              <p>{expiredAt}まで</p>
            </div>
            <AdSenseSquare />
          </div>
        )
      }
      <FooterMenu />
    </>
  )
}
