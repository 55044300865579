import React, { useState, useEffect } from 'react'
import { routes } from '../../config/routing'
import { messages } from '../../config/message'
import { createRoomAndUserAndToken } from '../../datastores/room'
import ButtonBlack from '../atoms/ButtonBlack'
import InputWhite from '../atoms/InputWhite'
import Recaptcha from '../atoms/Recaptcha'
import Loader from '../atoms/Loader'
import Header from '../organisms/Header'
import FooterMenu from '../organisms/FooterMenu'
import AdSenseSquare from '../AdSense/AdSenseSquare';
import './newRoom.scss'

export default function NewRoom(props) {

  const [title, setTitle] = useState('')
  const [recaptchaVerified, setRecaptchaVerified] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  // const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSubmitDisabled(title.trim() === '' || !recaptchaVerified);
  }, [title, recaptchaVerified]);

  const createRoomAction = async (e) => {
    e.preventDefault();

    try {
      const res = await createRoomAndUserAndToken(title);
      if (res.result) {
        setTimeout(() => {
          window.location.href = `${routes.createRoom}/${res.room.id}`;
        }, 1000);
      }
    } catch (err) {
      // TODO log出力
    }
  };

  return (
    <>
      <Header />
      <div className="newRoomArea">
        <form onSubmit={createRoomAction}>
          <InputWhite title={messages.room.title} value={title} changeAction={setTitle} />
          <Recaptcha setRecaptchaVerified={setRecaptchaVerified} />
          <ButtonBlack type='submit' value='トークを作成する' disabled={submitDisabled} />
        </form>
        <AdSenseSquare />
      </div>
      <FooterMenu />
    </>
  )
}
