import React from 'react'
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'
import FooterMenu from '../organisms/FooterMenu'
import './privacy.scss'

export default function Privacy(props) {
  return (
    <>
      <Header />
      <div className="fixedPageArea">
        <h1>プライバシーポリシー</h1>
        <p>Txtbase運営事務局（以下、「当事務局」という）は、個人情報の重要性を認識し、その適正な収集、利用、保護をはかるとともに、安全管理を行うため、以下のとおり、プライバシーポリシーを定め、継続的に個人情報の保護に努めます。</p>
        <h2>１．個人情報の定義</h2>
        <p>個人情報とは、個人情報の保護に関する法律に規定される生存する個人に関する情報（氏名、生年月日、その他の記述等により特定の個人を識別することができる情報）、ならびに他の情報と照合することで、特定の個人を識別することができることとなるものであると認識しています。</p>
        <h2>２．クッキー・IPアドレス情報</h2>
        <p>当事務局は、当事務局が運営するWebサイト（以下、「当事務局サイト」という）において、クッキー及びIPアドレスを以下の目的で使用することがあります。 クッキーとは、当事務局サイトに訪れた際に、PCやスマートフォンの端末におけるブラウザとサーバとの間で送受信されるデータを示します。クッキーは、お客様のブラウザに記憶され、サーバから要求があれば参照されることがありますが、当事務局サイトよりクッキーとして送信するデータには、個人を特定するような情報は含んでいません。従って、お客様のプライバシーを侵害することはございません。 IPアドレスとは、当事務局サイトに訪れた際に端末を特定できる識別番号を示します。IPアドレスは、端末の使用者を特定するような情報は含んでいません。従って、お客様のプライバシーを侵害することはございません。</p>
        <ul>
          <li>サーバで発生した障害や問題の原因を突き止めて解決するため</li>
          <li>当事務局サイトにおけるサービス内容の実装のため</li>
          <li>当事務局サイトにおけるサービス内容の改善のため</li>
          <li>当事務局サイトにおける利用状況の調査やマーケティング、広告活動のため</li>
        </ul>
        <h2>３．個人情報利用目的の特定</h2>
        <p>当事務局は、個人情報を取り扱うにあたって、取得した個人情報を以下の利用目的の範囲を超えて利用いたしません。</p>
        <ul>
          <li>登録情報を管理するため</li>
          <li>不正トランザクションの回避、窃取対策のための監視、犯罪行為から保護するため</li>
          <li>法的機関に対して応答するため</li>
          <li>お客様への連絡、当事務局がおすすめする商品・サービスなどの各種情報の提供を行うため</li>
          <li>お客様の年齢、職業、性別、趣味等に合わせて本サービス上の情報、本サービス及び広告配信をカスタマイズするため</li>
          <li>マーケティング（調査、アンケートの実施等）、データ分析や本サービスの研究や開発に利用するため</li>
          <li>お客様との契約、ならびに法令等に基づく権利の行使や義務の履行のため</li>
          <li>その他、上記に付随する目的のため</li>
        </ul>
        <h2>4．個人情報の適正な取得</h2>
        <p>当事務局は、適正かつ公正な手段により個人情報を取得し、お客様の意思に反する不正の手段により取得することはありません。また、第三者から個人情報を取得する場合でも、その取得する個人情報が、適正に取得されたものかを確認いたします。</p>
        <h2>5．個人情報利用目的の変更</h2>
        <p>当事務局は、個人情報の利用目的を変更する場合には、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲を超えては行わず、変更された利用目的について、お客様に通知し、または公表します。</p>
        <h2>6．個人情報の管理</h2>
        <p>当事務局は、個人情報の漏洩、滅失、き損及び不正利用等を防止し、その他の個人情報の安全管理が図られるために、合理的な安全、予防、是正措置を講じます。また、当事務局は個人情報を利用目的の範囲内において、正確かつ最新の状態で管理するように努めます。</p>
        <h2>7．第三者提供の制限</h2>
        <p>当事務局が保有するお客様の個人情報は、あらかじめお客様の同意を得ないで第三者に提供しません。ただし、以下の場合はお客様の同意なく提供することがあります。</p>
        <ul>
          <li>法令に基づく場合</li>
          <li>人の生命、身体または財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
          <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
          <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
          <li>個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめお客様に通知し、またはお客様が容易に知り得る状態に置いているとき</li>
        </ul>
        <h2>８．個人情報の開示</h2>
        <p>当事務局は、お客様から、個人情報の開示を求められたときは、お客様に対し、遅滞なく開示します。ただし、開示することにより、以下のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨をお客様に遅滞なく通知します。</p>
        <ul>
          <li>お客様または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
          <li>当事務局の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
          <li>他の法令に違反することとなる場合</li>
        </ul>
        <h2>9．個人情報の訂正等</h2>
        <p>当事務局は、お客様から、個人情報が真実でないという理由によって、内容の訂正、追加または削除（以下「訂正等」といいます）を求められた場合には、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨お客様に通知します。</p>
        <h2>１０．個人情報の利用停止等</h2>
        <p>当事務局は、お客様から、お客様の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由、または虚偽その他不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下「利用停止等」といいます）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨お客様に通知します。</p>
        <h2>１１．お問い合わせ</h2>
        <p>当事務局サイトのプライバシーポリシーに関するお問い合わせは、下記担当までお願いいたします。<br/>
        プライバシーポリシーに関するお問い合わせ先<br/>
        連絡先：info@txtbase.net</p>
      </div>
      <Footer />
      <FooterMenu />
    </>
  )
}
