import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from '@aws-amplify/ui-react'
import { createUserFromToken } from '../../datastores/user'
import { config } from '../../config/config'
import { messages } from '../../config/message'
import InputWhite from '../atoms/InputWhite'
import ButtonBlack from '../atoms/ButtonBlack'
import Header from '../organisms/Header'
import FooterMenu from '../organisms/FooterMenu'
import Error from '../organisms/Error'
import AdSenseSquare from '../AdSense/AdSenseSquare';
import './inviteRoom.scss'

export default function InviteRoom(props) {
  
  const { token } = useParams()
  const [isValidAccess, setIsValidAccess] = useState(undefined)
  const [url, setUrl] = useState('')

  useEffect(() => {
    createUserFromToken(token).then((res) => {
      if (res.result) {
        setIsValidAccess(true)
        setUrl(`/room/${res.id}`)
      } else
        setIsValidAccess(false)
    }).catch((err) => {
      // TODO ログ出力
      setIsValidAccess(false)
    })
  }, [])

  return (
    <>
      <Header />
      {isValidAccess &&
        <div className="inviteArea">
          <div className="inviteBox">
            <p>あなた専用のトークURLが生成されました！</p>
            <InputWhite value={`${config.siteUrl}${url}`} />
            <p>ホーム画面に追加していない方は、このURLにアクセスすることでトークを再開できます。</p>
          </div>

          <Link href={url}>
            <ButtonBlack value='トークに参加する' />
          </Link>
          <AdSenseSquare />
        </div>
      }

      {/* undefinedを条件から外すために厳密比較 */}
      {isValidAccess === false && <Error error={messages.errors.notFound} />}
      <FooterMenu />
    </>
  )
}
