export const routes = {
  top: '/',
  roomList: '/room_list',
  newRoom: '/new_room',
  createRoom: '/create_room',
  inviteRoom: '/invite_room',
  room: '/room',
  rule: '/rule',
  privacy: '/privacy',
  about: '/about',
  company: '',
}