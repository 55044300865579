import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { messages } from '../../config/message'
import { getUser } from '../../datastores/user'
import { getTalkRoomInfo } from '../../datastores/talk'
import InputTalk from '../molecules/InputTalk'
import InputPasscord from '../molecules/InputPasscord'
import Header from '../organisms/Header'
import FirstAccessSetting from '../organisms/FirstAccessSetting'
import Error from '../organisms/Error'
import HeaderRoom from '../organisms/HeaderRoom'
import TalkRoom from '../organisms/TalkRoom'
import UserSetting from '../organisms/UserSetting'
import './room.scss'

export default function Room(props) {
  const { userId } = useParams()
  const [user, setUser] = useState(null)
  const [isNotFound, setIsNotFound] = useState()
  const [showInputPassword, setShowInputPassword] = useState()
  const [isValidUser, setIsValidUser] = useState()
  const [isFirstAccess, setIsFirstAccess] = useState()
  const [room, setRoom] = useState(null)
  const [roomUsers, setRoomUsers] = useState([])
  const [showUserSetting, setShowUserSetting] = useState(false)

  const showUserSettingAction = () => {
    setShowUserSetting(true)
  }

  const backToTalkRoomAction = () => {
    setShowUserSetting(false)
  }

  const setIsValidPassword = () => {
    setShowInputPassword(false)
    setIsValidUser(true)
    setIsFirstAccess(false)
  }

  useEffect(async () => {
    const resUser = await getUser(userId)

    if (!resUser) {
      setIsNotFound(true)
      return
    }

    setUser(resUser)

    if (resUser.hasPassword &&
      !!resUser.enterAt &&
      showInputPassword === undefined &&
      isFirstAccess === undefined
    ) {
       setShowInputPassword(true)
    } else {
      setIsValidUser(!!resUser)
      setIsFirstAccess(!resUser.enterAt)
    }

    if (!isFirstAccess) {
      const res = await getTalkRoomInfo(resUser.roomID, resUser.id)

      setRoom(res.room)
      setRoomUsers(res.roomUsers)
    }
  }, [isFirstAccess])

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="referrer" content="no-referrer" />
      </Helmet>
      {showInputPassword &&
        <InputPasscord userId={user.id} setIsValidPassword={setIsValidPassword} />
      }
      {isValidUser && isFirstAccess &&
        <>
          <FirstAccessSetting user={user} setIsFirstAccess={setIsFirstAccess} />
        </>
      }
      {isValidUser && isFirstAccess === false && room &&
        <>
          <HeaderRoom name={room.name} showUserSettingAction={showUserSettingAction} />
          <TalkRoom me={user} roomUsers={roomUsers} roomId={room.id} />
          {showUserSetting &&
          <UserSetting user={user} roomId={room.id} backToTalkRoomAction={backToTalkRoomAction} setUserAction={setUser} />
          }
          <InputTalk userId={userId} roomId={room.id} />
        </>
      }
      { (isNotFound || isValidUser === false) &&
        <>
          <Header />
          <Error error={messages.errors.notFound} />
        </>
      }
    </>
  )
}
