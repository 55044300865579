import { DataStore } from 'aws-amplify'
import { Room } from '../models'
import { createUser } from './user'
import { createToken } from './token'
import { getLatestRoomOtherTalk, getLatestRoomTalk } from './talk'
import { getReads } from "./read";

export const getRoom = async (id) => {
  return await DataStore.query(Room, id)
}

export const createRoom = async (name) => {
  try {
    return await DataStore.save(
      new Room({
        name: name,
      })
    )
  } catch (error) {
    console.error("Error creating room: ", error);
  }
}

export const deleteRoom = async (id) => {
  const room = await DataStore.query(Room, id)
  return await DataStore.delete(room)
}

/*****************************************************************/

export const createRoomAndUserAndToken = async (name) => {
  const room = await createRoom(name)
  if (!room || !room.id) return { result: false }

  const user = await createUser(room.id)
  const token = await createToken(room.id)

  if (!user || !token) {
    console.error('Failed to create user or token');
    return { result: false }
  }

  return { result: true, room: room, user: user, token: token }
}

export const getRoomWithLatestTalk = async (roomId, userId) => {
  const room = await getRoom(roomId)
  if (room === undefined) return { room: undefined, latest: [], unread: false }
  // TODO roomが削除されている場合はIndexedDBにあるデータも削除

  const latest = await getLatestRoomTalk(roomId)

  let unread = false
  const latestOther = await getLatestRoomOtherTalk(roomId, userId)

  if (latestOther !== undefined) {
    const read = await getReads(userId, latestOther)

    if (read.length === 0) unread = true
  }

  return { room: room, latest: latest, unread: unread }
}


