import React, { useEffect } from 'react';
import './AdSense.scss';

const AdSenseSquare = () => {
  const isMobile = window.innerWidth <= 768;
  
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div className="adsense-wrapper">
      {
        isMobile ? (
          <ins
            className="adsbygoogle"
            style={{ display: "inline-block", width: "300px", height: "250px" }}
            data-ad-client="ca-pub-5262389544016402"
            data-ad-slot="4112656049"
          ></ins>
        ) : (
          <ins
            className="adsbygoogle"
            style={{ display: "inline-block", width: "336px", height: "280px" }}
            data-ad-client="ca-pub-5262389544016402"
            data-ad-slot="4476864965"
          ></ins>
        )
      }
    </div>
  );
};

export default AdSenseSquare;
